<!--
 * @Description: ================== 游戏介绍 =================
 * @Date: 2022-05-13 18:08:07
 * @Author: Zero
 * @FilePath: \guobao-web\src\components\Game.vue
 * @LastEditors: Zero
 * @LastEditTime: 2023-05-22 19:54:03
-->
<template>
  <div class="page page-game">
    <h2 class="title">游戏介绍</h2>
    <!-- <pre> -->
    <div class="content">
      <div class="content-item">
        <h4>《死神归来》</h4>
        死神归来再次降临，离殿困难重重，众多强力角色任你搭配，这次能否战到巅峰？快来搭配属于你的专属阵容。
        <img src="https://apk.najiahy.com/img/sssdir/1.PNG" alt="" />
      </div>
      <div class="content-item">
        <h4>【离殿征战】</h4>
        离殿征战 问鼎巅峰
        <img src="https://apk.najiahy.com/img/sssdir/2.PNG" alt="" />
      </div>
      <div class="content-item">
        <h4>【核心角色】</h4>
        培养核心 无可匹敌
        <img src="https://apk.najiahy.com/img/sssdir/3.PNG" alt="" />
      </div>
      <div class="content-item">
        <h4>【战灵王宫】</h4>
        战灵王宫 奖励丰富
        <img src="https://apk.najiahy.com/img/sssdir/4.PNG" alt="" />
      </div>
    </div>
    <!-- </pre> -->
  </div>
</template>

<style scss>
.content-item {
  display: inline-block;
  width: 50%;
  padding: 12px;
  box-sizing: border-box;
  vertical-align: bottom;
}
@media screen and (max-width: 768px) {
  .content-item {width: 100%;}
}
.content-item img {
  display: block;
  margin-top: 12px;
}
</style>
