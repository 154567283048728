<!--
 * @Description: ================== APP =================
 * @Date: 2022-05-13 18:01:16
 * @Author: Zero
 * @FilePath: \guobao-web\src\App.vue
 * @LastEditors: Zero
 * @LastEditTime: 2022-06-14 11:43:35
-->
<template>
  <div id="app">
    <nav>
      <router-link to="/game">游戏介绍</router-link>
      <router-link to="/service">技术支持及服务协议</router-link>
      <router-link to="/privacy">隐私政策</router-link>
      <router-link to="/contact">联系我们</router-link>
    </nav>
    <div id="app-content">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    const navEle = document.querySelector('nav')
    this.$router.beforeEach((to) => {
      if (window.location.href.indexOf('service') !== -1 || window.location.href.indexOf('privacy') !== -1) {
        if (window.innerWidth <= 768) {
          navEle.style.display = 'none'
          return
        }
      }

      navEle.style.display = 'black'
    })
  }
  // watch: {
  //   $router(router) {
  //     console.log(router)
  //   }
  // }
}
</script>

<style scss>
html, body {
  margin: 0;
  padding: 0;
  color: rgb(56, 55, 55);
  overflow-x: hidden;
  background-color: white;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

#app-content {
  margin: 0 auto;
  width: 980px;
}
nav {
  /* padding: 16px 0; */
  background-color: #618ced;
}
@media screen and (max-width: 768px) {
  body {
    box-sizing: border-box;
    /* width: calc(100vw - 16px) */
  }
  #app-content {
    padding: 8px;
    width: auto;
  }
  /* nav {
    display: none;
  } */
  .title {
    text-align: center;
  }
}
nav a {
  display: inline-block;
  padding: 16px 66px;
  color: rgb(250, 235, 235);
  text-decoration: none;
  font-size: 18px;
}
.router-link-active {
  border-bottom: 3px solid red;
}
.title {
  color: red;
}
a:hover {
  color: red;
}
pre {
  font-size: 16px;
  line-height: 28px;
}
img {
  margin: 0 auto;
  width: 320px;
}
</style>
